<template>
  <div
    class="modal fade confirm-modal"
    :class="{
      show: show,
      'confirm-modal--show': show,
      'confirm-modal--hide': !show,
    }"
    id="confirmModal"
    tabindex="-1"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmModalLabel">
            {{ title }}
          </h5>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn confirm-modal__cancel-btn"
            data-dismiss="modal"
            @click="onCancel"
          >
            {{ cancelBtn }}
          </button>
          <button
            type="button"
            class="btn confirm-modal__submit-btn"
            data-dismiss="modal"
            @click="onSubmit"
          >
            {{ submitBtn }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    show: { type: Boolean, default: false },
    message: { type: String, default: "" },
    title: { type: String, default: "" },
    submitBtn: { type: String, default: "はい" },
    cancelBtn: { type: String, default: "いいえ" },
  },
  methods: {
    async onSubmit() {
      this.$emit("onSubmit");
    },
    async onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style scoped>
@media (max-width: 1560px) and (min-width: 1386px) {
  .modal-dialog{
    width: 430px;
  }  
}
@media (max-width: 1385px) {
  .modal-dialog{
    width: 390px;
  }  
}
.modal-title,
.modal-body {
  color: #000;
}
.modal-body {
  text-align: center;
}
.modal-header,
.modal-footer {
  border: none;
}
.modal-footer {
  justify-content: center;
  padding: 1.5rem;
}
.modal-content {
  border: 0.5px solid #b6b1b1;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #000000;
}
.confirm-modal--show {
  display: block;
}
.confirm-modal--hide {
  display: none;
}
.confirm-modal__submit-btn {
  width: 115px;
  min-height: 32px;
  border-radius: 5px;
  font-size: 16px;
  color: #ffffff;
  background: rgba(18, 108, 243, 0.9);
  border: 0.5px solid #b6b1b1;
}

.confirm-modal__cancel-btn{
  width: 115px;
  min-height: 32px;
  border-radius: 5px;
  font-size: 16px;
  color: #948c8c;
  background: #ffffff;
  border: 1px solid #c4c4c4;
}
</style>
